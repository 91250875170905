import React, { useContext, useMemo } from 'react';
import styles from './ReportList.module.scss';
import {
  Customer,
  CustomerContext,
  QRCodeModal,
  User,
  UserContext,
  UserTitleBase
} from 'ui-smartforce-settings';
import { ReportValue } from '../../Models';
import { ReportCard } from '../ReportCard/ReportCard';
import { canAmendReport } from '../../Helpers';

export type ReportListItemTooltipType = 'default' | 'disabled';

export interface ReportListItem extends ReportValue {
  tooltipType?: ReportListItemTooltipType;
}

function setFirstAmend(
  reports: ReportValue[],
  showAmend: boolean,
  user: User,
  customer: Customer
): ReportListItem[] {
  if (!showAmend) {
    return reports;
  } else {
    let result: ReportListItem[] = [];
    let firstAmendIndex: number = -1;

    reports.forEach((report, index) => {
      if (firstAmendIndex === -1 && canAmendReport(report, user, customer)) {
        firstAmendIndex = index;
        result = [...result, { ...report, tooltipType: 'default' }];
      } else {
        result = [...result, report];
      }
    });

    // If there aren't reports to amend, show tooltip in the first report
    if (firstAmendIndex === -1) {
      result[0].tooltipType = 'disabled';
    }

    return result;
  }
}

interface ReportQRData {
  officerId: string;
  officerName: string;
  officerTitle?: UserTitleBase;
  incidentNumber: string;
  eventIdentifier?: string;
}

export interface ReportListProps {
  reports: ReportValue[];
  showAmend?: boolean;
}

export const ReportList = ({
  reports,
  showAmend = true
}: ReportListProps): React.ReactElement<ReportListProps> => {
  const user = useContext(UserContext).user as User;
  const customer = useContext(CustomerContext).customer as Customer;

  const [isQrModalOpen, setIsQrModalOpen] = React.useState<boolean>(false);
  const [selectedReportQRData, setSelectedReportQRData] =
    React.useState<ReportQRData>();

  const onScanQrCode = (report: ReportValue) => {
    setSelectedReportQRData({
      officerId: report.general_information.officer_id_number as string,
      officerName: report.general_information.officer_name as string,
      officerTitle: report.general_information.created_by_user?.title,
      incidentNumber: report.general_information.incident_number as string,
      eventIdentifier: report.general_information.state_related
        ?.to_connect_reports?.event_identifier as string
    });

    setIsQrModalOpen(true);
  };

  const reportList: ReportListItem[] = useMemo(
    () => setFirstAmend(reports, showAmend, user, customer),
    [reports, showAmend, user, customer]
  );

  return (
    <div className={styles.reportList}>
      {selectedReportQRData && (
        <QRCodeModal
          baseUrl={process.env.REACT_APP_CC_PUBLIC_URL as string}
          isOpen={isQrModalOpen}
          title="Scan the QR code"
          subTitle="The citizen can scan this QR code to follow the case."
          user={{
            officer_id: selectedReportQRData.officerId,
            name: selectedReportQRData.officerName,
            title: selectedReportQRData.officerTitle
          }}
          incidentNumber={selectedReportQRData.incidentNumber}
          eventIdentifier={selectedReportQRData.eventIdentifier}
          onClose={() => setIsQrModalOpen(false)}
        />
      )}

      <div className={styles.content}>
        {reportList.map((report: ReportListItem) => (
          <ReportCard
            key={report.general_information.incident_number as string}
            report={report}
            showAmend={showAmend}
            onScanQrCode={(activeReport: ReportValue) =>
              onScanQrCode(activeReport)
            }
          />
        ))}
      </div>
    </div>
  );
};
