import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './MultipleReportsModal.module.scss';
import {
  SFDialog,
  SFScrollable,
  SFScrollableRefHandler,
  SFSpinner
} from 'sfui';
import { FullReportGroupType } from '../FullReportModal/FullReportModal';
import { ReportValue } from '../../Models';
import { FullReport } from '../FullReportModal/FullReport/FullReport';
import { FullReportModalFooter } from '../FullReportModal/FullReportModalFooter/FullReportModalFooter';
import { getReport } from '../../Services/ReportService';
import { handleError } from '../../Helpers';
import { useHistory } from 'react-router-dom';
import {
  MultipleReportsModalOption,
  MultipleReportsModalSelect
} from './MultipleReportsModalSelect/MultipleReportsModalSelect';

function getReportByIncident(reports: ReportValue[], incident: string) {
  return reports.find(
    (report: ReportValue) =>
      report.general_information.incident_number === incident
  );
}

export interface MultipleReportsModalProps {
  isOpen: boolean;
  groupType: FullReportGroupType;
  options: MultipleReportsModalOption[];
  defaultSelected?: string;
  onAmend: (report: ReportValue) => void;
  onClose: () => void;
}

export const MultipleReportsModal = ({
  isOpen,
  groupType,
  options,
  defaultSelected,
  onAmend,
  onClose
}: MultipleReportsModalProps): React.ReactElement<MultipleReportsModalProps> => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reports, setReports] = useState<ReportValue[]>([]);
  const [selectedIncident, setSelectedIncident] = useState<string>(
    defaultSelected || ''
  );

  const onChange = useCallback(
    async (incident: string) => {
      setSelectedIncident(incident);
      setIsLoading(true);
      let report = getReportByIncident(reports, incident);
      if (!report) {
        try {
          report = await getReport(incident);
        } catch (e) {
          handleError(e, history);
        }
        setReports((reports: ReportValue[]) => [
          ...reports,
          report as ReportValue
        ]);
      }

      setIsLoading(false);
    },
    [reports, history]
  );

  useEffect(() => {
    const init = async () => {
      if (isOpen && defaultSelected) {
        setIsLoading(true);
        setSelectedIncident(defaultSelected);
        try {
          const report = await getReport(defaultSelected);
          setReports((reports: ReportValue[]) => [
            ...reports,
            report as ReportValue
          ]);
          setIsLoading(false);
        } catch (e) {
          handleError(e, history);
        }
      } else if (!isOpen) {
        setReports([]);
      }
    };

    init();
  }, [isOpen, defaultSelected, history]);

  const selectedReport = getReportByIncident(reports, selectedIncident);

  const refReport = useRef<SFScrollableRefHandler>(null);

  return (
    <SFDialog
      PaperProps={{ className: styles.multipleReportsModal }}
      open={isOpen}
    >
      <div className={styles.container}>
        <SFScrollable containerClassName={styles.scrollableContainer}>
          <SFScrollable className={styles.scrollableSelect}>
            <MultipleReportsModalSelect
              groupType={groupType}
              options={options}
              selected={selectedIncident}
              onChange={onChange}
            />
          </SFScrollable>

          <SFScrollable
            className={`${styles.scrollableReport} ${
              isLoading ? styles.isLoading : ''
            }`}
            ref={refReport}
          >
            {isLoading && <SFSpinner size={40} />}
            {!isLoading && selectedReport && (
              <FullReport
                report={selectedReport}
                onAmend={() => onAmend(selectedReport)}
              />
            )}
          </SFScrollable>
        </SFScrollable>

        <FullReportModalFooter onClose={onClose} />
      </div>
    </SFDialog>
  );
};
