import React from 'react';
import styles from './PatterMapInfo.module.scss';
import { SFScrollable } from 'sfui';
import { PatternMapItem } from './PatternMapItem/PatternMapItem';
import { PatterMapItemIncidentData } from './PatternMapItem/PatterMapItemIncident/PatterMapItemIncident';

export interface DataDict {
  [key: string]: PatterMapItemIncidentData[];
}

export interface PatterMapInfoProps {
  className?: string;
  data: DataDict;
  isClickable?: boolean;
  onIncidentClick?: (data: PatterMapItemIncidentData) => void;
}

export const PatterMapInfo = ({
  className = '',
  data,
  isClickable = true,
  onIncidentClick
}: PatterMapInfoProps): React.ReactElement<PatterMapInfoProps> => {
  return (
    <SFScrollable className={`${styles.patterMapInfo} ${className}`}>
      <div className={styles.items}>
        {Object.keys(data).map((key: string, index: number) => (
          <PatternMapItem
            key={`${key}-${index}`}
            title={key}
            incidents={data[key]}
            isClickable={isClickable}
            onIncidentClick={onIncidentClick}
          />
        ))}
      </div>
    </SFScrollable>
  );
};
