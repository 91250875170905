import React, { useContext, useMemo, useState } from 'react';
import styles from './FilterMap.module.scss';
import { FilterSelector } from './FilterSelector/FilterSelector';
import { FilterMapOption } from './FilterSelector/FilterSelectorItem/FilterSelectorItem';
import { Coords, FilterMapDataType } from '../../../../Models/Widget';
import { getMarker, transformCoordsToLatLng } from '../../../../Helpers';
import { SFButton, SFThemeType } from 'sfui';
import {
  ANALYTICS_DAY_PALETTE,
  ANALYTICS_NIGHT_PALETTE
} from '../../../../Constants/Colors';
import { WidgetPanel } from '../../WidgetPanel/WidgetPanel';
import { MediaContext, Marker } from 'ui-smartforce-settings';
import { WidgetDrawer } from '../../WidgetDrawer/WidgetDrawer';
import {
  useFilteredReports,
  useMapOptions,
  useMarkerClick
} from '../../../../Hooks';
import { ReportsMap } from '../../../ReportsMap/ReportsMap';
import { ThemeTypeContext } from 'ui-smartforce-settings';
import { NavPanelContext } from '../../../../Context/NavPanelContext';

interface ColorDict {
  [key: string]: string;
}

const getFilterOptions = (colors: ColorDict): FilterMapOption[] => {
  return Object.keys(colors).map((key: string) => ({
    label: key,
    color: colors[key]
  }));
};

const removeDuplicates = (data: FilterMapDataType[]): FilterMapDataType[] => {
  return data.filter(
    (item, index, arr) =>
      arr.findIndex((val) => val.name === item.name) === index
  );
};

const getLabels = (data: FilterMapDataType[]): string[] => {
  return removeDuplicates(data).map((item) => item.name);
};

const getColorsDict = (
  data: FilterMapDataType[],
  themeType: SFThemeType
): ColorDict => {
  const colors: ColorDict = {};
  const palette =
    themeType === 'day' ? ANALYTICS_DAY_PALETTE : ANALYTICS_NIGHT_PALETTE;

  removeDuplicates(data).forEach((value: FilterMapDataType, index: number) => {
    colors[value.name] = palette[index % palette.length];
  });

  return colors;
};

const removeReportDuplicates = (
  reports: FilterMapDataType[],
  filters: string[]
): FilterMapDataType[] => {
  return reports
    .filter((v: FilterMapDataType) => filters.includes(v.name))
    .reduce<FilterMapDataType[]>(
      (acc: FilterMapDataType[], r: FilterMapDataType) =>
        acc.findIndex(
          (rv: FilterMapDataType) => rv.incident_number === r.incident_number
        ) !== -1
          ? acc
          : [...acc, r],
      []
    );
};

export interface FilterMapProps {
  title: string;
  data: FilterMapDataType[];
  isDrawerOpen?: boolean;
  onDrawerClose?: () => void;
}

export const FilterMap = ({
  title,
  isDrawerOpen = false,
  data,
  ...props
}: FilterMapProps): React.ReactElement<FilterMapProps> => {
  const { isCollapsed } = useContext(NavPanelContext);
  const { isPhone } = useContext(MediaContext);
  const { themeType } = useContext(ThemeTypeContext);
  const [checked, setChecked] = useState<string[]>(getLabels(data));
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [drawerChecked, setDrawerChecked] = useState<string[]>(getLabels(data));

  const { reportsWithCoords, reportsWithoutCoords } =
    useFilteredReports<FilterMapDataType>(data);

  const colorsDict = useMemo(
    () => getColorsDict(data, themeType),
    [data, themeType]
  );
  const options = useMapOptions(data);

  // Filter report with multiple label values but same id
  const reportsWithoutCoordsNoDuplicates: FilterMapDataType[] = useMemo(
    () => removeReportDuplicates(reportsWithoutCoords, checked),
    [reportsWithoutCoords, checked]
  );

  const reportsWithCoordsNoDuplicates: FilterMapDataType[] = useMemo(
    () => removeReportDuplicates(reportsWithCoords, checked),
    [reportsWithCoords, checked]
  );

  const { onMarkerClick } = useMarkerClick(reportsWithCoordsNoDuplicates);

  const onDrawerClose = () => {
    setDrawerChecked(checked);
    props.onDrawerClose && props.onDrawerClose();
  };

  const onDrawerSave = () => {
    setChecked(drawerChecked);
    props.onDrawerClose && props.onDrawerClose();
  };

  return (
    <div
      className={`${styles.filterMap} ${
        isCollapsed ? styles.navPanelCollapsed : ''
      }`}
    >
      <ReportsMap
        {...options}
        reportsWithoutCoords={reportsWithoutCoordsNoDuplicates}
      >
        {reportsWithCoords.map(
          (item: FilterMapDataType, index: number): JSX.Element | undefined => (
            <Marker
              key={`${item.incident_number}-${index}`}
              visible={checked.indexOf(item.name) !== -1}
              {...getMarker(
                transformCoordsToLatLng(item.coords as Coords),
                colorsDict[item.name],
                index + 1
              )}
              onClick={() => onMarkerClick('closeCoords', item)}
            />
          )
        )}
      </ReportsMap>

      <FilterSelector
        className={styles.filterSelector}
        options={getFilterOptions(colorsDict)}
        value={checked}
        onChange={(newChecked: string[]) => setChecked(newChecked)}
      />

      {!isPhone && (
        <WidgetPanel
          isOpen={isPanelOpen}
          onToggle={() => setIsPanelOpen((open: boolean) => !open)}
        >
          <FilterSelector
            options={getFilterOptions(colorsDict)}
            value={checked}
            onChange={(newChecked: string[]) => setChecked(newChecked)}
          />
        </WidgetPanel>
      )}

      {isPhone && (
        <WidgetDrawer
          title={title}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
        >
          <FilterSelector
            options={getFilterOptions(colorsDict)}
            value={drawerChecked}
            onChange={(checked: string[]) => {
              setDrawerChecked(checked);
            }}
          />

          <div className={styles.drawerSave}>
            <SFButton fullWidth size="large" onClick={onDrawerSave}>
              Save Changes
            </SFButton>
          </div>
        </WidgetDrawer>
      )}
    </div>
  );
};
