import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';
import { AGENCY_REPORTS_AMEND } from '../../../Constants/RolesAndPermissions';
import {
  TourContext,
  UserContext,
  checkPermissions,
  useCloseTour
} from 'ui-smartforce-settings';
import { handleError } from '../../../Helpers';
import { ReportValue } from '../../../Models';
import { amendReport } from '../../../Services/ReportService';
import CancelModal from '../ReportWizard/CancelModal/CancelModal';
import { ContactFormView } from '../ReportWizard/ContactFormView/ContactFormView';
import styles from './AmendReport.module.scss';

interface AmendReportState {
  report: ReportValue;
  redirectTo: string;
}

export const AmendReport = (): React.ReactElement<{}> | null => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { setIsFeatureReminderOpen } = useContext(TourContext);
  const locationState = location.state as AmendReportState;
  const [isCancelModalOpen, setCancelIsModalOpen] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const report = locationState ? locationState.report : undefined;

  useCloseTour([
    { tourId: 6, step: 2 },
    { tourId: 6, step: 3 },
    { tourId: 10, step: 2 },
    { tourId: 10, step: 3 }
  ]);

  useEffect(() => {
    if (!locationState) {
      history.replace('/error');
    }
  }, [locationState, history]);

  const onCancel = () => {
    setCancelIsModalOpen(true);
  };

  const onDiscardChanges = () => {
    setCancelIsModalOpen(false);
    history.goBack();
  };

  const onModalClose = () => {
    setCancelIsModalOpen(false);
  };

  const onSubmit = async (contactValue: ReportValue, isTour?: boolean) => {
    try {
      setIsLoading(true);
      await amendReport({
        ...contactValue,
        created_in_version: process.env.REACT_APP_CC_VERSION
      });

      if (isTour) {
        setIsFeatureReminderOpen(true);
      }

      history.replace(locationState.redirectTo, {
        showMainAlert: true,
        mainAlertData: {
          message: 'The report was updated successfully.',
          autoHideDuration: 3600
        }
      });
    } catch (e) {
      console.error('AmendReport::onSubmit');
      handleError(e, history);
    }
  };

  const showSupervision: boolean = checkPermissions(
    AGENCY_REPORTS_AMEND,
    user?.role.permissions
  );

  return (
    <div className={styles.amendReport}>
      {isLoading && <Loader />}

      {!isLoading && report && (
        <React.Fragment>
          <CancelModal
            isOpen={isCancelModalOpen}
            isUpdate
            onDiscard={onDiscardChanges}
            onClose={onModalClose}
          />

          <ContactFormView
            report={report}
            deviceDuration={
              report.general_information.device?.duration as number
            }
            showSupervision={showSupervision}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </React.Fragment>
      )}
    </div>
  );
};
